import { Routes } from '@angular/router';
import { HomeCalendarComponent } from './home-calendar/home-calendar.component';
import { EventFormComponent } from './event-form/event-form.component';
import { PlanningComponent } from './planning/planning.component';
import { CostListComponent } from './cost-list/cost-list.component';
import { PlanningFaComponent } from './planning-fa/planning-fa.component';
import { authGuard } from './guards/auth.guard';
import { AuthenticationComponent } from './authentication/authentication.component';

export const routes: Routes = [
    {path: 'calendar', component: HomeCalendarComponent,
        children: [
            {path: ':idUser', component: HomeCalendarComponent},
            {path: ':idUser/:sDate', component: HomeCalendarComponent},
        ],
        canActivate: [authGuard]
    },
    {path: 'planning', component: PlanningComponent, canActivate: [authGuard]},
    {path: 'event/:idEvent', component: EventFormComponent, canActivate: [authGuard]},
    {path: 'event/:idEvent/costList', component: CostListComponent, canActivate: [authGuard]},
    {path: 'event/:idEvent/planning-fa', component: PlanningFaComponent, canActivate: [authGuard]},
    {path: 'token', component: AuthenticationComponent},
    {path: '**', redirectTo: 'calendar', pathMatch: 'full'}
];
