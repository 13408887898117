@if((showInstall$ | async)){
    <div class="toast install step-1" [ngClass]="{'open': (installStep$ | async) === 1}">
        <h2>Installer l'application</h2>
        <p>Souhaitez-vous ajouter l'application à votre écran d'accueil ?</p>
        <div class="actions">
            <a class="cta" (click)="installStep2()">Suivant</a>
            <a class="cancel" (click)="cancelInstall()">Fermer</a>
        </div>
    </div>
    <div class="toast install step-2" [ngClass]="{'open': (installStep$ | async) === 2}">
        <h2>Installation</h2>
        <p>Pour installer l'application, suivez les instructions correspondantes au système de votre téléphone :</p>
        <h3>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#87C527"><path d="M40-240q9-107 65.5-197T256-580l-74-128q-6-9-3-19t13-15q8-5 18-2t16 12l74 128q86-36 180-36t180 36l74-128q6-9 16-12t18 2q10 5 13 15t-3 19l-74 128q94 53 150.5 143T920-240H40Zm240-110q21 0 35.5-14.5T330-400q0-21-14.5-35.5T280-450q-21 0-35.5 14.5T230-400q0 21 14.5 35.5T280-350Zm400 0q21 0 35.5-14.5T730-400q0-21-14.5-35.5T680-450q-21 0-35.5 14.5T630-400q0 21 14.5 35.5T680-350Z"/></svg>
            Android
        </h3>
        <p>Cliquez sur le bouton ci-dessous, Android vous proposera alors de rajouter l'application sur votre écran d'accueil.</p>
        <div class="actions">
            <a class="cta" (click)="install()">Installer l'application</a>
        </div>
        <h3>
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24" viewBox="0 0 1000 1000">
                <path fill="#999" d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/>
              </svg>
            Safari / iOS
        </h3>
        <ol>
            <li>Appuyez sur le bouton "Partager" situé en bas de l'écran (icône représentant un carré avec une flèche)</li>
            <li>Sélectionnez l'option "Ajouter à l'écran d'accueil"</li>
            <li>L'icône apparaitra alors sur votre écran d'accueil</li>
        </ol>
        <div class="actions">
            <span></span>
            <a class="cancel" (click)="cancelInstall()">Fermer</a>
        </div>
    </div>
}
@if((showUpdate$ | async)){
    <div class="toast update" [ngClass]="{'open': (updateStep$ | async) === 1}">
        <h2>Mise à jour disponible</h2>
        <p>Une mise à jour de l'application est disponible.</p>
        <p>Souhaitez-vous l'installer ?</p>
        <p class="warning">Veuillez réaliser la mise à jour dès que possible pour ne pas rencontrer de dysfonctionnements.</p>
        <div class="actions">
            <a class="cta" (click)="update()">Mettre à jour</a>
            <a class="cancel">Fermer</a>
        </div>
    </div>
}