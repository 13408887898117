import { Component, EventEmitter, Output } from '@angular/core';
import { IconComponent } from "../widgets/icon/icon.component";
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { constructorUser, User } from '../models/user';
import { CommonModule } from '@angular/common';
import { map, Observable, Subscription } from 'rxjs';
import { TooltipUserComponent } from '../tooltip-user/tooltip-user.component';
import { NetworkStatusService } from '../services/network-status.service';

@Component({
  selector: 'app-calendar-header',
  standalone: true,
  imports: [IconComponent, CommonModule, TooltipUserComponent],
  providers: [],
  templateUrl: './calendar-header.component.html',
  styleUrl: './calendar-header.component.scss'
})
export class CalendarHeaderComponent {

  tooltipOpen: boolean = false;

  userSelected!: User | null;

  currentUser: User | null = constructorUser();
  userSubscription?: Subscription;
  isAnotherUserSelected: boolean = false;

  isOnline$ : Observable<boolean>;
  
  @Output() clickRefreshCalendar: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private router: Router,
    private userSvc: UserService,
    //private toastSvc: ToastService
    statusService: NetworkStatusService
  ) {

    this.isOnline$ = statusService.status$.pipe(
      map((status) => status.isOnline)
    )

  }

  ngOnInit() {
    this.userSubscription = this.userSvc.user$.subscribe((user) => {
      this.currentUser = user; 
      this.isAnotherUserSelected = this.userSvc.isAnotherUser();
    });
  }

  openPlanning() {
    this.router.navigate(['planning']);
  }

  clickOnButtonHomeOrClose() {
    if (this.isAnotherUserSelected) {
      this.userSvc.removeUserChosen();
      this.isAnotherUserSelected = this.userSvc.isAnotherUser();
    } else {
      this.tooltipOpen = !this.tooltipOpen;
    }

    this.currentUser = this.userSvc.getUserChosen();
  }

  closeTooltipUser(_event: boolean) {
    console.log(_event);
    if (_event) {
      this.tooltipOpen = !this.tooltipOpen;
    }
  }

  refreshCalendar() {
    this.clickRefreshCalendar.emit(true);
  }
}
