import { Component } from '@angular/core';
import { HomeCalendarComponent } from './home-calendar/home-calendar.component';
import { RouterOutlet } from '@angular/router';
import { interval, map, merge, Observable, of, switchMap, take } from 'rxjs';

import { ToastComponent } from "./toast/toast.component";
import { NetworkStatusService } from './services/network-status.service';
import { CommonModule } from '@angular/common';
import { InstallToastComponent } from "./install-toast/install-toast.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, HomeCalendarComponent, RouterOutlet, ToastComponent, InstallToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})

export class AppComponent {
  title = 'PlanPopLight';

  toastAlreadyDisplayed : boolean = false;

  showToast$ : Observable<boolean>;

  constructor(
    offlineSvc: NetworkStatusService
  ) {

    this.showToast$ = offlineSvc.status$.pipe(
      switchMap((status) => {
        if(!status.isOnline && !this.toastAlreadyDisplayed){
          this.toastAlreadyDisplayed = true;
          return merge(
            of(true),
            interval(5000).pipe(map(() => false), take(1))
          )
        }
        return of(false);
      })
    );

  }

}
