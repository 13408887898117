
<div class="cost-list-container">
    <div class="cost-list-header">
        <div class="cost-list-icon">
            <app-icon class="cost-list-close" (click)="backToEvent()" [iconLabel]="'close'"></app-icon>
        </div>
        <div class="cost-list-title">
            <h1 class="regular">GESTION DES FRAIS</h1>
            <h4 class="bold">{{event.EventLabelFA}}</h4>
        </div>
        <div class="cost-list-checkbox">
            @if (isUserAuthorized && (online$ | async)) {
                <div class="fake-div-checkbox" (click)="changeCostCheckValue()"></div>
            }
            <input [disabled]="!isUserAuthorized || !(online$ | async)" type="checkbox" [checked]="eventHasNoCost" class="checkbox-cost"/> <!-- (change)="changeCostCheckValue($event)" -->
            <h6 class="bold">Aucun Frais</h6> 
        </div>
    </div>
    <div class="cost-list-content">
        @if (!eventHasNoCost) {
            <div *ngFor="let cost of event.EventCosts">
                <h4 class="light cost-list-content-date">{{eventDate | date : 'fullDate'}}</h4>
                <app-cost-card [EventCost]="cost" [deletableEvent]="isUserAuthorized && !!(online$ | async)" (deleteEventCost)="deleteEventCost($event)"></app-cost-card>
            </div>
        }
    </div>
    <div class="cost-list-button-total">
        @if (!eventHasNoCost) {
            @if (isUserAuthorized && (online$ | async)) {
                <app-icon class="cost-list-plus" iconLabel="plus" (click)="tooltipOpen = !tooltipOpen"></app-icon>
            }
            <app-tooltip-cost [openCloseTooltip]="tooltipOpen" [eventFA]="event.EventFA" (addEventCost)="newEventCost($event)" (addEventCostAuto)="newEventCostAuto($event)"></app-tooltip-cost>
            <h6 class="bold">TOTAL</h6>
            <h1 class="bold">{{event.EventTotalCost}} €</h1>
        }
    </div>

    @if (modalOpen) {
        <div id="dialog" role="dialog" aria-modal="true" class="dialog-confirm">
            <h4 class="regular">Si vous décochez la case "frais", tous les frais présent dans la liste seront supprimés.</h4>
            <h4 class="regular">Confirmez-vous cette action ?</h4>
            <div class="dialog-confirm-content">
                <button (click)="openModal(false)"><h5 class="light">Non</h5></button>
                <button (click)="openModal(true)"><h5 class="light">Oui</h5></button>
            </div>
        </div>
        <div class="backdrop" (click)="openModal(undefined)"></div>
    }
</div>


