import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  userToken: string = '';

  constructor() { }

  setToken(_token: string) {
    sessionStorage.setItem('token', JSON.stringify(_token));
  }

  deleteToken() {
    this.userToken = '';
    sessionStorage.removeItem('token');
    this.redirectLogin();
  }

  redirectLogin() {    
    const url = new URL("https://example.com");
    url.host = "login.microsoftonline.com";
    url.pathname = "organizations/oauth2/v2.0/authorize";
    url.searchParams.append("client_id", environment.microsoft.client_id);
    url.searchParams.append("redirect_uri", environment.microsoft.redirect_url);
    url.searchParams.append("scope", "user.read");
    url.searchParams.append("response_type", "code");
    url.searchParams.append("response_mode", "query");

    if(environment.microsoft.state !== ''){
      url.searchParams.append("state", environment.microsoft.state);
    }
    console.log("Redirect to Microsoft Auth", url.href, true);
    window.location.assign(url.href);
  }
}
