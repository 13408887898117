import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventCost, EventCostAuto, EventCostObject, EventCostTabObject, EventCostTypeObject } from '../models/event-cost';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CostService {

  constructor(
    private http: HttpClient 
  ) { }

  createEventCost(_eventId: number, _eventCostCreated: EventCost, _forcedCreation: number): Observable<EventCostObject> {
    return this.http.post<EventCostObject>('/api/EventCost?EventID='+ _eventId +'&ForcedModification=' + _forcedCreation, _eventCostCreated)
  }

  createEventCostAuto(_eventId: number, _eventCostAutoCreated: EventCostAuto): Observable<EventCostTabObject> {
    return this.http.post<EventCostTabObject>('/api/EventCostAuto?EventID='+ _eventId, _eventCostAutoCreated)
  }

  deleteEventCost(_eventCostDeleted: EventCost): Observable<EventCostTypeObject> {
    return this.http.delete<EventCostTypeObject>('/api/EventCost?EventCostId=' + _eventCostDeleted.EventCostId)
  }
}
