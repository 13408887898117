<div class="planning-fa-container">
    <div class="planning-fa-header">
        <div class="planning-fa-title-content">
            <app-icon class="planning-fa-close-button" (click)="backToCalendar()" [iconLabel]="'close'"></app-icon>
            <h2 class="regular planning-fa-title">Planning FA</h2>
            <div class="planning-fa-close-button"></div>
        </div>
        <div class="planning-fa-subtitle">
            <h5 class="regular subtitle-left">{{event.EventLabelMission}}</h5>
            <h5 class="light subtitle-right">{{event.EventLabelFA}}</h5>
        </div>
    </div>
    @if (isLoaded) {
        <div class="planning-fa-dashboard">
            <div class="dashboard-info-content">
                <div class="info-left">
                    <h5 class="bold">Budget jour :</h5>
                    <h5 class="bold">Budget écoulé :</h5>
                    <h5 class="bold">Budget restant :</h5>
                </div>
                <div class="info-right">
                    <h5 class="light">{{eventListFA.EventListFADayBudget}}</h5>
                    <h5 class="light">{{eventListFA.EventListFADayTotal}}</h5>
                    <h5 class="light">{{eventListFA.EventListFADayRemaining}}</h5>
                </div>
            </div>
            <div class="dashboard-info-gauge">
                <h4 class="bold">0</h4>
                <div class="gauge" [ngStyle]="{'grid-template-columns': 'repeat(' + tabGaugeStep.length + ', 1fr)'}">
                    <div *ngFor="let step of tabGaugeStep; first as isFirst; last as isLast">
                       <div *ngIf="step == 1" class="gauge-step" [ngClass]="{'gauge-first-radius': isFirst, 'gauge-last-radius': isLast}"></div> 
                    </div>
                </div>
                <h4 class="bold">{{eventListFA.EventListFADayBudget}}</h4>
            </div>
        </div>
        <div class="planning-user-list">
            <div *ngFor="let event of eventListFA.EventListFAPlanning.PlanningDayUserEvents">
                <div>
                    <h3 class="bold">{{transformDate(event.DayUserEventDate)}}</h3>
                    <hr>
                </div>
                    
                
                <div class="planning-user-list-content">
                <div class="planning-user-list-content-header">
                    <div class="title">
                        <h3 class="bold">{{event.DayUserEventUser.UserShortName}}</h3>
                        <h5 class="bold">{{event.DayUserEventUser.UserEntityLabel}}</h5>
                    </div>
                </div>
                @if (event.DayUserEvents.length > 0) {
                    <div class="planning-user-event">
                        <app-event-card *ngFor="let event of event.DayUserEvents" [eventObj]="event" [deletableEvent]="false" [clickableEvent]="false"></app-event-card>
                    </div>
                }
                </div>
            </div>
        </div>
    }
</div>