import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityObject } from '../models/entity-object';
import { HttpClient } from '@angular/common/http';
import { Entity } from '../models/entity';
import { Combo, ComboObject } from '../models/combo-object';
import { EventCostType, EventCostTypeObject } from '../models/event-cost';

@Injectable({
  providedIn: 'root'
})
export class ParamService {

  entityList!: Entity[];
  comboList!: Combo[];
  CostTypeList!: EventCostType[];

  constructor(
    private http: HttpClient
  ) { }

  getEntityList(): Observable<EntityObject> {
    return this.http.get<EntityObject>('/api/UserList');
  }

  getParamCombo(): Observable<ComboObject> {
    return this.http.get<ComboObject>('/api/Param');
  }

  getCostTypeList(): Observable<EventCostTypeObject> {
    return this.http.get<EventCostTypeObject>('/api/CostTypeList');
  }

  getEntity() {
    //GET user List
    this.getEntityList().subscribe(
      {
        next: (_entityList: EntityObject) => {
          this.entityList = _entityList.Entities; 
          localStorage.setItem("PPL-EntityList", JSON.stringify(this.entityList));
        },
        error: _error => {
          if (_error.error) {
            alert("Error lors du chargement des entités :" + _error.error.Error.ErrorMessage);
          }
        }
      }
    )
  }

  getParam() {
    this.getParamCombo().subscribe(
      {
        next: (_combo: ComboObject) => {
          this.comboList = _combo.Combo; 
          localStorage.setItem("PPL-StatutList", JSON.stringify(this.comboList.find(_combo => _combo.ComboLabel == "Statut")?.ComboValues));
          localStorage.setItem("PPL-TypeCostList", JSON.stringify(this.comboList.find(_combo => _combo.ComboLabel == "Type de frais")?.ComboValues));
          localStorage.setItem("PPL-VehiculeList", JSON.stringify(this.comboList.find(_combo => _combo.ComboLabel == "Véhicule")?.ComboValues));
        },
        error: _error => {
          if (_error.error) {
            alert("Error lors du chargement des valeurs des combos : " + _error.error.Error.ErrorMessage);
          }
        }
      }
    )
  }

  getCostType() {
    this.getCostTypeList().subscribe(
      {
        next: (_costTypeList: EventCostTypeObject) => {
          this.CostTypeList = _costTypeList.CostTypes; 
          localStorage.setItem("PPL-TypeCostListObject", JSON.stringify(this.CostTypeList));
        },
        error: _error => {
          if (_error.error) {
            alert("Error lors du chargement des types de frais : " + _error.error.Error.ErrorMessage);
          }
        }
      }
    )
  }
}
