/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ApplicationRef, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, concat, fromEvent, interval, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  private canInstallSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private canUpdateSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private deferredPrompt: any = null;

 
  public get canInstallApp$(): Observable<boolean> {
    return this.canInstallSub.asObservable();
  }

  public get canUpdateApp$(): Observable<boolean> {
    return this.canUpdateSub.asObservable();
  }

  constructor(
    appRef: ApplicationRef,
    private updates: SwUpdate,
    root : ActivatedRoute
  ) {

    //Detect if a new version is available
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(async () => {
      try {

        const updateFound = await updates.checkForUpdate();
        console.log("Update Check", updateFound);
        if (!!updateFound) {
          this.canUpdateSub.next(true);
        }
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });


    // Detect if we can add the app to homepage
    fromEvent(window, 'beforeinstallprompt').subscribe(
      (e) => {
        // Avoid calling the install prompt
        e.preventDefault();

        // Store the event to use it later
        this.deferredPrompt = e;

        // signal we can install the app
        this.canInstallSub.next(true);
      }
    );
  }

  public install(): void {
    console.log(this.deferredPrompt);

    if (this.deferredPrompt === null) {
      return;
    }

    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice.then((choice: { "outcome": string }) => {
      if (choice.outcome === 'accepted') {
        this.canInstallSub.next(false);
      }

      this.deferredPrompt = null;
    })
  }

  public update(): void {
    if(!this.canUpdateSub.value){
      return;
    }

    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
