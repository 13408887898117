import { CanActivateFn, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { inject } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authSvc = inject(AuthenticationService);
  const userSvc = inject(UserService);

  if (!userSvc.isUserAuthenticated()) {
    //Redirect to login AMSL
    authSvc.redirectLogin();
    return false;
  }

  return true;
};
