import { Injectable } from '@angular/core';
import { catchError, filter, map, Observable, of, ReplaySubject, Subject, switchMap, take, tap } from 'rxjs';
import { ActivationEnd, Router } from '@angular/router';
import { DayUserEvent } from '../models/day-user-event';
import { NetworkStatusService } from './network-status.service';
import { getFirstDayOf, getLastDayOf, stringToYearMonth, YearMonth, yearMonthToString } from '../models/year-month';
import { EventService } from './event.service';
import { UserService } from './user.service';

interface PlanningCached{
  key: string;
  cachedAtEpoch: number,
  events: DayUserEvent[] 
}

// Cache duration is in milleseconds
// Cached months are valid 1 minute only when we're online
const CACHE_DURATION_ONLINE = 60_000; 
// But, if we get offline, they're valid 1 day
const CACHE_DURATION_OFFLINE = 1440 * 60_000;


@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  private __calendarSubject: Subject<boolean> = new Subject<boolean>();
  private __dateFromRoute: ReplaySubject<string> = new ReplaySubject(1);

  public get refreshCalendar$() : Observable<boolean>{
    return this.__calendarSubject.asObservable();
  }

  public get dateFromRoute$() : Observable<string>{
    return this.__dateFromRoute.asObservable();
  }
  
  constructor(
    private userSvc: UserService,
    private networkStatusSvc: NetworkStatusService,
    private eventSvc: EventService,
    router: Router,
  ) {

    router.events.pipe(
      filter((event): event is ActivationEnd => event instanceof ActivationEnd),
      map(evt => evt.snapshot.params),
      filter(v => 'sDate' in v),
      map(params => params['sDate']),
      filter(s => s !== ''),
    ).subscribe({
      next: (s) => {
        this.__dateFromRoute.next(s);
      }
    })
  }

  refreshCalendar() {
    this.__calendarSubject.next(true);
  }

  public clearCache(ym: YearMonth, userId: string = this.userSvc.userConnected.UserId.toString()): void{
    sessionStorage.removeItem(this.constructKey(ym, userId));
  }


  public getUserPlanning(ym: YearMonth, userId: string, forceRefresh: boolean = false): Observable<DayUserEvent[]>{
    console.warn("Fetching planning", userId, ym, forceRefresh);

    // Using network status service to know if we can fetch from API or must use 
    return this.networkStatusSvc.status$.pipe(
      take(1), // So we just use the current value, no need for further triggers ?
      switchMap((status) => {
        if(!forceRefresh || !status.isOnline){
          const cached = this.fetchFromCache(ym, userId, status.isOnline ? CACHE_DURATION_ONLINE : CACHE_DURATION_OFFLINE);
      
          if(cached.found){
            return of(cached.events);
          }
        }

        console.log("Calling webservice for planning");
        return this.eventSvc.getUserEventList(getFirstDayOf(ym), getLastDayOf(ym), userId).pipe(
          map(userEventListObject => userEventListObject.UserEventListPlanning.PlanningDayUserEvents),
          tap((events) => { this.storeToCache(ym, userId, events) }),
          catchError((err, caught) => {
            const cached = this.fetchFromCache(ym, userId, CACHE_DURATION_OFFLINE);
            if(cached.found){
              return of(cached.events);
            }

            return of([])
          })
        )
      })
    )
  }
  
  private fetchFromCache(ym: YearMonth, userId: string, cacheDuration: number) : {found: boolean, events: DayUserEvent[]}{
    const cached = sessionStorage.getItem(this.constructKey(ym, userId));

    if(cached !== null){
      const planningCached = JSON.parse(cached) as PlanningCached;
      const cachedSince = (new Date()).valueOf() - planningCached.cachedAtEpoch;
      if(cachedSince < cacheDuration){
        console.info("Fetched events from cache", planningCached, cacheDuration);
        return { found: true, events: planningCached.events };
      }
    }

    return { found: false, events: []};
  }

  private storeToCache(ym: YearMonth, userId: string, events: DayUserEvent[]): boolean{
    const plannings : PlanningCached[] = [];

    events.forEach(ev => {
      const planning = plannings.filter(p => p.key === yearMonthToString(stringToYearMonth(ev.DayUserEventDate)));
      if(planning.length > 0){
        planning[0].events.push(ev);
      }
      else{
        const newPlan : PlanningCached = {
          cachedAtEpoch: (new Date()).valueOf(),
          events: [ev],
          key: yearMonthToString(stringToYearMonth(ev.DayUserEventDate))
        } 
        plannings.push(newPlan);
      }
    });
    
    try {
      // We only store cache for the user's own agenda, not the others
      if(this.userSvc.getUserChosen().UserId == this.userSvc.userConnected.UserId){
        plannings.forEach(pp => {
          console.log("Caching planning", userId, pp.key);
          sessionStorage.setItem('PPL-PL-'+userId+'-'+pp.key, JSON.stringify(pp));
        })
      }
      return true;
    } catch (error) {
      return false;
    }
  }


  private constructKey(ym: YearMonth, userId: string): string{
    return 'PPL-PL-'+userId+'-'+yearMonthToString(ym);
  }
  
}
