import { Component, EventEmitter, Input, Output } from '@angular/core';
//import { Event } from '../models/event';
import { Router } from '@angular/router';
import { IconComponent } from '../widgets/icon/icon.component';
import { Event } from '../models/event';

@Component({
  selector: 'app-event-card',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './event-card.component.html',
  styleUrl: './event-card.component.scss'
})
export class EventCardComponent {

  constructor(
    private router: Router
  ) {}

  event!: Event;
  isDeletable: boolean = true;
  isClickable: boolean = true;

  @Input() set eventObj(_eventObj: Event) {
    if (_eventObj) {
      this.event = _eventObj;
    }
  };

  @Input() set deletableEvent(_isDeletable: boolean) {
    this.isDeletable = _isDeletable;
  };

  @Input() set clickableEvent(_isClickable: boolean) {
    this.isClickable = _isClickable;
  };

  @Output() deleteEvent: EventEmitter<Event> = new EventEmitter();

  clickOnEvent(_event: Event) {
    //If the event isn't deletable is special case that mean he isn't clickable
    if (_event.EventId > 0 && this.isClickable) {
      this.router.navigate(['event/', _event.EventId], {
        state: { _event }
      });
    }
  }

  clickDeleteEvent() {
    this.deleteEvent.emit(this.event);
  }
}
