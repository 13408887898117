<div class="tooltip-content">
    <div class="tooltip-background" (click)="tooltipOpen = !tooltipOpen" [ngClass]="{'tooltip-close' : !tooltipOpen}"></div>
    <div class="tooltip" [ngClass]="{'tooltip-close' : !tooltipOpen}">
        <div class="triangle"></div>
        <div class="tooltip-list-entity">
            <div *ngFor="let entity of entityList" (click)="selectEntity(entity)">
                @if (entity.EntityId == entitySelected?.EntityId) {
                    <h5 class="regular tooltip-entity-selected">{{entity.EntityLabel}}</h5>
                } @else {
                    <h5 class="regular tooltip-entity">{{entity.EntityLabel}}</h5>
                }
            </div>
        </div>
        <div class="tooltip-form">
            @if (entitySelected == null) {
                <h6 class="bold help-title">Sélectionnez une entité pour pouvoir choisir un Groupe</h6> 
            }
            <div class="tooltip-form-field">
                <h5 class="bold field-name">Groupe</h5> 
                <select class="custom-select" [(ngModel)]="groupSelected" [disabled]="entitySelected == null" (ngModelChange)="changeGroup($event)">
                    <option disabled selected [ngValue]="null">Choisir un groupe</option>
                    <option [ngValue]="group" *ngFor="let group of entitySelected?.EntityGroupes"><h6 class="regular">{{group.GroupLabel}}</h6></option>
                </select>
            </div>
            <div class="tooltip-form-field">
                <h5 class="bold field-name">Mission</h5> 
                <select class="custom-select" [(ngModel)]="missionSelected" [disabled]="groupSelected == null" (ngModelChange)="changeMission($event)">
                    <option disabled selected [ngValue]="null">Choisir une mission</option>
                    <option [ngValue]="mission" *ngFor="let mission of groupSelected?.GroupMissions"><h6 class="regular">{{mission.MissionLabel}}</h6></option>
                </select>
            </div>
            <div class="tooltip-form-field">
                <h5 class="bold field-name">Fiche action</h5> 
                <select class="custom-select" [(ngModel)]="ficheActionSelected" [disabled]="missionSelected == null">
                    <option disabled selected [ngValue]="null">Choisir une fiche action</option>
                    <option [ngValue]="ficheAction" *ngFor="let ficheAction of missionSelected?.MissionFAs"><h6 class="regular">{{ficheAction.FicheActionLabel}}</h6></option>
                </select>
            </div>
            <div class="tooltip-form-date-container">
                <div class="tooltip-form-date-button">
                    @if(tabDate.length < 5) {
                        <app-icon class="add-date-button" [iconLabel]="'plus'" (click) ="addDateField()"></app-icon>
                        <h6 class="bold">Ajouter</h6>
                    }
                </div>
                <div class="tooltip-form-date-list-container">
                    <div class="tooltip-form-date-list">
                        <div class="tooltip-form-date-field">
                            <h5 class="bold field-name">Jour 1</h5> 
                            <input type="date" [(ngModel)]="tabDate[0]">
                            <div class="date-list-icon-close"></div>
                        </div>
                        <!-- INPUT DAY 2 -->
                        @if (tabDate.length > 1) {
                            <div class="tooltip-form-date-field">
                                <h5 class="bold field-name">Jour 2</h5> 
                                <input type="date" [(ngModel)]="tabDate[1]">
                                <app-icon class="date-list-icon-close" iconLabel="close" (click)="removeDateField(1)"></app-icon>
                            </div>
                        }
                        <!-- INPUT DAY 3 -->
                        @if (tabDate.length > 2) {
                            <div class="tooltip-form-date-field">
                                <h5 class="bold field-name">Jour 3</h5> 
                                <input type="date" [(ngModel)]="tabDate[2]">
                                <app-icon class="date-list-icon-close" iconLabel="close" (click)="removeDateField(2)"></app-icon>
                            </div>
                        }
                        <!-- INPUT DAY 4 -->
                        @if (tabDate.length > 3) {
                            <div class="tooltip-form-date-field"> 
                                <h5 class="bold field-name">Jour 4</h5> 
                                <input type="date" [(ngModel)]="tabDate[3]">
                                <app-icon class="date-list-icon-close" iconLabel="close" (click)="removeDateField(3)"></app-icon>
                            </div>
                        }
                        <!-- INPUT DAY 5 -->
                        @if (tabDate.length > 4) {
                            <div class="tooltip-form-date-field"> 
                                <h5 class="bold field-name">Jour 5</h5> 
                                <input type="date" [(ngModel)]="tabDate[4]">
                                <app-icon class="date-list-icon-close" iconLabel="close" (click)="removeDateField(4)"></app-icon>
                            </div>
                        }
                    </div>
                    <h6 class="light help-day-sentence">{{tabDate.length}} jour{{tabDate.length > 1 ? 's' : ''}} ajouté{{tabDate.length > 1 ? 's' : ''}}</h6> 
                </div>
            </div>
        </div>
        <div class="tooltip-button-form">
            <button [disabled]="!checkFormValidity()" (click)="createEvent()"><h5 class="light">Créer</h5></button>
        </div>
    </div>
</div>
