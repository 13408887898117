<div class="calendar-header">
    <div><h6 class="bold dayLabel">L</h6></div>
    <div><h6 class="bold dayLabel">M</h6></div>
    <div><h6 class="bold dayLabel">M</h6></div>
    <div><h6 class="bold dayLabel">J</h6></div>
    <div><h6 class="bold dayLabel">V</h6></div>
    <div><h6 class="bold dayLabel">S</h6></div>
    <div><h6 class="bold dayLabel">D</h6></div>
</div>
<div class="calendar-content">
    @if(calDays$ | async; as days){
        @for(day of days; track day.fullDate){
            <div 
              class="day dow-{{day.dayOfWeek}}" 
              (click)="clickOnDay(day.fullDate)" 
              [ngClass]="{'day-first': day.number === '1'}"
            >
                <div
                class="day-button numberDayBlock hover-circle"
                [ngClass]="{
                    'day-event-color' : day.nbEvents > 0, 
                    'day-event-color-selected' : day.isSelected, 
                    'day-today-color' : day.nbEvents > 0 && day.isToday, 
                    'day-today-color-event' : day.isToday && day.nbEvents === 0,
                    'day-event-color-conflict' : day.isConflictEvent
                }" 
                >
                    <h6 class="light dayNumber">
                        {{day.number}}
                    </h6>
                </div>
            </div>
        }
    }
</div>