import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IconComponent } from '../widgets/icon/icon.component';
import { ParamService } from '../services/param.service';
import { Combo, ComboValue } from '../models/combo-object';
import { constructorEventCost, constructorEventCostAuto, constructorEventCostType, EventCost, EventCostAuto, EventCostType } from '../models/event-cost';
import { CostService } from '../services/cost.service';
import { FicheAction } from '../models/entity';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-tooltip-cost',
  standalone: true,
  imports: [IconComponent, CommonModule, FormsModule, ReactiveFormsModule],
  providers: [DecimalPipe],
  templateUrl: './tooltip-cost.component.html',
  styleUrl: './tooltip-cost.component.scss'
})

export class TooltipCostComponent {

  tooltipOpen: boolean = true;

  typeCombo!: EventCostType[];
  carCombo!: ComboValue[];
  costForm!: FormGroup;
  costFormAuto!: FormGroup;

  newCost!: EventCost;
  newCostAuto!: EventCostAuto;

  isTravelCost: boolean = false;
  ficheAction!: FicheAction;
  costTypeSelected: EventCostType = constructorEventCostType();

  //Listen open/close of the component
  @Input() set openCloseTooltip(_isOpen: boolean) {
    this.tooltipOpen = !this.tooltipOpen;
  };

  @Input() set eventFA(_eventFA: FicheAction) {
    this.ficheAction = _eventFA;
  };

  @Output() addEventCost: EventEmitter<EventCost> = new EventEmitter<EventCost>();
  @Output() addEventCostAuto: EventEmitter<EventCostAuto> = new EventEmitter<EventCostAuto>();

  constructor(
    private paramSvc: ParamService,
    private costSvc: CostService,
    private decimalPipe: DecimalPipe,
    private userSvc: UserService
  ) {
    this.initCostForm();
    this.initCostAutoForm();
  }

  ngOnInit() {
    //Get Combo Type Cost
    let _tempTypeCostList = localStorage.getItem("PPL-TypeCostListObject");
    if (_tempTypeCostList) {
      //Don't display CostTypeAuto 
      this.typeCombo = JSON.parse(_tempTypeCostList).filter((_type: EventCostType)=> _type.CostTypeAuto == false);
    }

    //Get Combo State
    let _tempListAuto = localStorage.getItem("PPL-VehiculeList");
    if (_tempListAuto) {
      this.carCombo = JSON.parse(_tempListAuto);
    }
  }

  initCostForm(): void {
    this.costForm = new FormGroup({
      costType: new FormControl(0, [Validators.required]),
      costDetail: new FormControl('', [Validators.required]),
      costTTC: new FormControl('', [Validators.required, Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]),
      costTVA: new FormControl('', [Validators.required, Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]),
      costExpense: new FormControl(false, [Validators.required]),
      costRebillable: new FormControl(false, [Validators.required]),
      costOvernight: new FormControl(0, [Validators.required, Validators.pattern(/^[0-9]+$/)]),
      costKmCO2: new FormControl(0, [Validators.required, Validators.pattern(/^[0-9]+$/)]),
    });
  }

  initCostAutoForm(): void {
    this.costFormAuto = new FormGroup({
      costVehicule: new FormControl(this.userSvc.getUserChosen().UserAutoVehicule, [Validators.required]),
      costKm: new FormControl({value: '', disabled: true}, [Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]),
      costKilometers: new FormControl('', [Validators.required, Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]),
      costHighwayToll: new FormControl('', [Validators.required, Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]),
    });
  }

  createCost() {
    console.log("COST FORM",this.costForm);
    console.log(this.newCost);
    
    if (this.isTravelCost) {

      this.setNewObjectEventCostAuto();

      if (this.costFormAuto.valid) {
        this.addEventCostAuto.emit(this.newCostAuto);
        console.log("JE CREER", this.newCostAuto);
        
        this.tooltipOpen = false;
        this.costTypeSelected = constructorEventCostType();
        this.initCostAutoForm();
      } else {
        console.log("INVALID FORM COST AUTO");
        alert("Données saisies dans le formulaire invalides")
      }
    } else {

      this.setNewObjectEventCost();

      if (this.costForm.valid && this.newCost.EventCostType.CostTypeId > 0) {
      
        console.log("EMIT DE COST ;:", this.newCost);
        
        this.addEventCost.emit(this.newCost);
        this.tooltipOpen = false;
        this.costTypeSelected = constructorEventCostType();
        this.initCostForm();
      } else {
        console.log("INVALID FORM COST");
        alert("Données saisies dans le formulaire invalides")
      }
    }
  }

  setNewObjectEventCost() {
    //Reset newObject
    this.newCost = constructorEventCost();

    if (this.costForm.get('costTVA')?.value == '') {
      this.costForm.get('costTVA')?.setValue('0');
    }
    
    //Get value Object
    this.newCost.EventCostType = this.costTypeSelected;
    this.newCost.EventCostDetail = this.costForm.get('costDetail')?.value;
    this.newCost.EventCostTTC = Number(this.costForm.get('costTTC')?.value.replace(',', '.'));
    this.newCost.EventCostTVA = Number(this.costForm.get('costTVA')?.value.replace(',', '.'));
    this.newCost.EventCostExpense = this.costForm.get('costExpense')?.value;
    this.newCost.EventCostRebillable = this.costForm.get('costRebillable')?.value;
    this.newCost.EventCostOvernight = this.costForm.get('costOvernight')?.value;
    this.newCost.EventCostKmCO2 = this.costForm.get('costKmCO2')?.value;
  }

  setNewObjectEventCostAuto() {
    //Reset newObjectAuto
    this.newCostAuto = constructorEventCostAuto();

    this.newCostAuto.EventCostAutoVehicule = Number(this.costFormAuto.get('costVehicule')?.value);
    this.newCostAuto.EventCostAutoCostKilometers = Number(this.costFormAuto.get('costKilometers')?.value.replace(',', '.'));
    this.newCostAuto.EventCostAutoCostHighwayToll = Number(this.costFormAuto.get('costHighwayToll')?.value.replace(',', '.'));
  }

  onChangeCostType(_costTypeId: number) {
    if (!this.typeCombo) return;

    let _tempCostType = this.typeCombo.find(_type => _type.CostTypeId == _costTypeId);
    
    if (_tempCostType) {
      this.costTypeSelected = _tempCostType;
      this.costForm.get('costExpense')?.setValue(this.costTypeSelected.CosTypeExpense);
      this.costForm.get('costRebillable')?.setValue(this.costTypeSelected.CostTypeRebillable);
      this.costForm.get('costDetail')?.setValue(this.costTypeSelected.CostTypeLabel);
      /* if (this.costTypeSelected.CostTypeWithAmountTVA) {
        this.costForm.get('costTVA')?.setValue(this.costTypeSelected.CostTypeRateTVA);
      } */
    }
  }
}
