import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, distinctUntilChanged, fromEvent, map, merge, Observable, of, ReplaySubject, Subject, switchMap, timer } from 'rxjs';


export const BYPASS_OFFLINE_CHECK = new HttpContextToken<boolean>(() => false);

export interface NetworkStatus{
  isOnline: boolean,
  triggeredBy: string
}
    

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {

  private __errorFromApi : Subject<NetworkStatus> = new Subject<NetworkStatus>();
  private __status : ReplaySubject<NetworkStatus> = new ReplaySubject<NetworkStatus>(1);

  public get status$(): Observable<NetworkStatus>{
    return this.__status.asObservable();
  }

  public get isOnline$(): Observable<boolean> {
    return this.__status.asObservable().pipe(
      map(status => status.isOnline)
    )
  }

  constructor(
    http: HttpClient
  ) { 

    // Browser event when it detects it is offline by itself (before we ask the API)
    const isOfflineEvent$ = fromEvent(window, "offline").pipe(
      map((ev) : NetworkStatus => { return { isOnline: false, triggeredBy: 'browser' }})
    );

    const isOnlineEvent$ = fromEvent(window, "online").pipe(
      map((ev) : NetworkStatus => { return { isOnline: true, triggeredBy: 'browser' }})    
    )

    const testApi$ : Observable<NetworkStatus> = http.get('/api?description', {context: new HttpContext().set(BYPASS_OFFLINE_CHECK, true)}).pipe(
      map((ev) : NetworkStatus => { return { isOnline: true, triggeredBy: 'test' }}),
      catchError((err) => { return of({ isOnline: false, triggeredBy: 'test'})}),
    )

    const intervalFetch = timer(0, 5_000).pipe(
      switchMap((n) => testApi$),
    )

    // Default is value from the navigator, so we can know if we are already offline
    const initialStatus$: Observable<NetworkStatus> = of({
      isOnline: true,
      triggeredBy: 'initial'
    })

    merge(
      initialStatus$, 
      isOfflineEvent$,      
      isOnlineEvent$,       // Browser event when it detects it is back online
      this.__errorFromApi   // Triggered by interceptor
    ).pipe(
      switchMap((status) => {
        if(!status.isOnline){      // If offline, we launch a periodic test to detect we are back online ASAP.
          return merge(     // Merge allows us two things
            of(status),      // - Immediately fire "false", to warn all subscribers we are offline
            intervalFetch   // - Launch the interval check that could fire true at some point
          );
        }
        return of(status);  // If online, just transmit the information to the subscribers
      }),
      distinctUntilChanged((prev, curr) => prev.isOnline === curr.isOnline), // Avoid triggering the subscribers too much
    ).subscribe({
      next: (val) => {
        console.log("offline/online service: ", val);
        this.__status.next(val)
      }
    })
  }

  public triggerOffline(){
    this.__errorFromApi.next({ isOnline: false, triggeredBy: 'fetch' });
  }
}