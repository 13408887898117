import { Component, Input } from '@angular/core';
import { IconComponent } from '../widgets/icon/icon.component';
import { Entity, FicheAction, Groupes, Mission } from '../models/entity';
import { UserService } from '../services/user.service';
import { EntityObject } from '../models/entity-object';
import { CommonModule } from '@angular/common';
import { ParamService } from '../services/param.service';
import { FormsModule } from '@angular/forms';
import { EventService } from '../services/event.service';
import { EventCreate } from '../models/event-create';
import { Router } from '@angular/router';
import { CalendarService } from '../services/calendar.service';
import { Day } from '../models/day';

@Component({
  selector: 'app-tooltip-event',
  standalone: true,
  imports: [IconComponent, CommonModule, FormsModule],
  templateUrl: './tooltip-event.component.html',
  styleUrl: './tooltip-event.component.scss'
})
export class TooltipEventComponent {

  tooltipOpen: boolean = true;

  entityList!: Entity[];
  entitySelected!: Entity | null;
  groupSelected!: Groupes | null;
  missionSelected!: Mission | null;
  ficheActionSelected!: FicheAction | null;
  defaultDaySelected!: Day;

  tabDate: string[] = [''];
  isLoading: boolean = false;

  //Listen open/close of the component
  @Input() set openCloseTooltip(_isOpen: boolean) {
    this.tooltipOpen = !this.tooltipOpen;
    this.entityList = this.paramSvc.entityList;
  };

  //Listen day selected
  @Input() set daySelected(_day: Day | undefined) {
    if(_day === null || _day === undefined) return;
    
    this.defaultDaySelected = _day;
    this.tabDate[0] = this.convertDate(this.defaultDaySelected.DayDateLabel);
    //let date= new Date(this.convertDate(this.defaultDaySelected.DayDateLabel));
    //console.log(date);
  };

  constructor(
    private userSvc: UserService,
    private eventSvc: EventService,
    private paramSvc: ParamService,
    private calendarSvc: CalendarService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.groupSelected = null;
    this.missionSelected = null;
    this.ficheActionSelected = null;
  }

  selectEntity(_entity: Entity) {
    this.entitySelected = _entity;
    this.resetForm(false);
  }

  addDateField() {
    if(this.tabDate.length < 5) {
      this.tabDate.push('');
    }
  }

  removeDateField(index: number) {
    this.tabDate.splice(index, 1);
  }

  checkFormValidity() {    
    return (this.ficheActionSelected?.FicheActionId && this.tabDate[0] != "")
  }

  createEvent(_forcedSecondtTime: boolean = false) {
    let _tempDayDate: string[] = [];
    
    this.tabDate.map((_date, index) => {
      _tempDayDate[index] = this.formatDate(_date);
    });

    console.log("CREATE EVENT", _tempDayDate.filter(_date => _date !== ""));
    
    if (this.checkFormValidity()) {

      if (!this.ficheActionSelected?.FicheActionId) return;

      this.isLoading = true;

      let _eventCreate: EventCreate = {
        EventFAId: this.ficheActionSelected?.FicheActionId,
        EventUser: this.userSvc.getUserChosen(),
        EventDates: _tempDayDate
      }

      this.eventSvc.createEvent(_eventCreate, +_forcedSecondtTime).subscribe({
        next: response => {

          if (response.Error.ErrorCode == 206) {
            console.log("206 HALF SUCCESS createEvent Response : ", response);
            if (confirm(response.Error.ErrorMessage)) {
              this.createEvent(true)
            } else {
              return;
            }
          } else {
            console.log("SUCCESS createEvent Response : ", response);
            this.isLoading = false;
            this.openCloseTooltip = false;
            
            this.router.navigate(['calendar/', this.userSvc.getUserChosen().UserId, _tempDayDate[0]], {queryParams: { refresh: "true" }}); 
            this.resetForm();
  
            //Reset Date form date 2-5
            this.tabDate = [];
            this.tabDate[0] = this.convertDate(this.defaultDaySelected.DayDateLabel);
          }
        },
        error: _error => {
          //CATCH 206 - > OUINON  -> OUI = FORCED
          console.log("Error lors de l'enregistrement de l'événement ", _error)
          if (_error.error) {
            alert("Error lors de l'enregistrement de l'événement : " + _error.error.Error.ErrorMessage);
          }
        },
      });
    } else {
      console.log("Manque un param");
      alert("Erreur dans le formulaire de création de l'évenement")
    }
  }

  //REPLACE BY DATEPIPE TANSFORM 
  formatDate(date: string): string {
    // Vérifie que la date est bien au format 'YYYY-MM-DD'
    if (date && date.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return date.replace(/-/g, '');
    }
    return '';
  }

  convertDate(date: string): string {
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    return `${year}-${month}-${day}`;
  }

  resetForm(_resetEntity: boolean = true) {
    if (_resetEntity) {
      this.entitySelected = null;
    }
    this.groupSelected = null;
    this.missionSelected = null;
    this.ficheActionSelected = null;
  }

  changeGroup(_event : any) {
    this.missionSelected = null;
    this.ficheActionSelected = null;
  }

  changeMission(_event : any) {
    this.ficheActionSelected = null;
  }
}
