import { Component, Input } from '@angular/core';
import { Event } from '../models/event';
import { CommonModule } from '@angular/common';
import { IconComponent } from "../widgets/icon/icon.component";
import { EventCardComponent } from "../event-card/event-card.component";
import { TooltipEventComponent } from '../tooltip-event/tooltip-event.component';
import { Day } from '../models/day';
import { constructorUserNote, UserNote } from '../models/user-note';
import { UserService } from '../services/user.service';
import { EventService } from '../services/event.service';
import { CalendarService } from '../services/calendar.service';
import { Observable, Subscription } from 'rxjs';
import { NetworkStatusService } from '../services/network-status.service';

@Component({
  selector: 'app-calendar-event-list',
  standalone: true,
  imports: [CommonModule, IconComponent, EventCardComponent, TooltipEventComponent],
  templateUrl: './calendar-event-list.component.html',
  styleUrl: './calendar-event-list.component.scss'
})
export class CalendarEventListComponent {

  tooltipOpen: boolean = false;
  daySelected!: Day;
  listEvent!: Event[];
  noteUser: UserNote = constructorUserNote();
  tabNoteUser: boolean[] = [false,false,false,false,false];
  todayDate = new Date(); //new Date('2024-08-23') -> To test friday notation
  isTodayFriday: boolean = false;

  //Authorisation User
  isUserAuthorized: boolean = false;
  isUserConnected: boolean = true;
  userSubscription?: Subscription;

  isOnline$: Observable<boolean>;

  @Input() set eventDaySelected(_daySelected: Day | null) {   
    if (_daySelected !== null) {
      this.daySelected = _daySelected;
      this.listEvent = _daySelected.DayEvents;
    }
  };

  constructor(
    private userSvc: UserService,
    private eventSvc: EventService,
    private calendarSvc: CalendarService,
    private networkSvc: NetworkStatusService
  ) {

    this.isOnline$ = networkSvc.isOnline$;

  }
  
  ngOnInit() {
    this.checkIsTodayFriday();

    if (this.isTodayFriday) {
      this.getUserNote();
    }
    
    //Authorisation User - Listen User Change
    this.userSubscription = this.userSvc.user$.subscribe((user) => {
      this.checkUserAuthorisation();
    });
  }

  getUserNote() {
    this.userSvc.getUserNote().subscribe(
    {
      next: _userNoteObj => {
        this.noteUser.UserNoteValue = _userNoteObj.UserNote.UserNoteValue;
        for (let index = 0; index < _userNoteObj.UserNote.UserNoteValue; index++) {
          this.tabNoteUser[index] = true;
        }
      },
      error: _error => {
        console.log("Erreur lors du chargement de la notation", _error);
        if (_error.error) {
          alert("Erreur lors du chargement de la notation : " + _error.error.Error.ErrorMessage);
        }
      }
    });
  }

  setUserNote(_userNote: UserNote) {
    this.noteUser.UserNoteValue = _userNote.UserNoteValue;
    this.userSvc.setUserNote(_userNote).subscribe({
      next: response => {
        console.log("Update Value OK ", _userNote.UserNoteValue + "/5");
      },
      error: _error => {
        console.log("Erreur lors de la mis à jour de la notation", _error);
        if (_error.error) {
          alert("Erreur lors de la mis à jour de la notation : " + _error.error.Error.ErrorMessage);
        }
      }
    });
  }

  changeUserNote(_index: number) {    
    if (this.noteUser?.UserNoteValue && this.noteUser?.UserNoteValue == _index + 1) return;

    //Update interface 0 -> 4
    for (let index = 0; index < 5; index++) {
      if (index <= _index) {
        this.tabNoteUser[index] = true;
      } else {
        this.tabNoteUser[index] = false;
      }
    }
    
    let _userNote: UserNote = {
      UserNoteValue: _index + 1
    }

    //Update Note User 1 -> 5
    this.setUserNote(_userNote);
  }

  deleteEvent(_eventDeleted: Event) {
    console.log("delete de l'event :", _eventDeleted);
    if (confirm("Confirmer la suppression de cet évenement ?")) {
      this.eventSvc.deleteEvent(_eventDeleted).subscribe(
        {
          next: (_sucessEventDeleted: any) => {
            console.log("Succes event",_sucessEventDeleted);
            this.calendarSvc.refreshCalendar();
          },
          error: _error => {
            console.log("Erreur lors de la suppression d'un évènement", _error);
            if (_error.error) {
              alert("Erreur lors de la suppression d'un évènement : " + _error.error.Error.ErrorMessage);
            }
          }
        }
      )
    }
  }

  checkIsTodayFriday() {
    if (this.todayDate.getDay() == 5) {
      this.isTodayFriday = true;
    } else {
      this.isTodayFriday = false;
    }
  }
  
  //Authorisation User
  checkUserAuthorisation() {
    this.isUserAuthorized = this.userSvc.getUserAuthorisation(this.userSvc.getUserChosen().UserId);
    this.isUserConnected = this.userSvc.getUserChosen().UserId == this.userSvc.userConnected.UserId;
  }
}
