<div class="tooltip-background" (click)="closeTooltip()"></div>
<div class="tooltip" [ngClass]="(toolTipMode == 'user') ? 'tooltip-user' : 'tooltip-user-planning'">
    <!-- <div class="triangle"></div> -->
    <div class="tooltip-list-entity" >
        <div *ngFor="let entity of entityList" (click)="selectEntity(entity)">
            @if (entity.EntityId == entitySelected?.EntityId) {
                <h5 class="regular tooltip-entity-selected">{{entity.EntityLabel}}</h5>
            } @else {
                <h5 class="regular tooltip-entity">{{entity.EntityLabel}}</h5>
            }
        </div>
    </div>
    <div class="tooltip-filter">
        <input placeholder="Recherche" type="text" #input [(ngModel)]="filterInputFieldValue" (keyup.enter)="filterUser()"> 
        <div class="tooltip-filter-icon-list">
            <app-icon (click)="filterUser()" class="tooltip-filter-icon" [iconLabel]="'filter'"></app-icon>
            @if (isListFiltered) {
                <app-icon (click)="removefilterUser()" class="tooltip-filter-icon" [iconLabel]="'close'"></app-icon>
            }
        </div>
    </div>
    <div class="tooltip-list-user">
        <div *ngFor="let user of entitySelectedFiltered?.EntityUsers" (click)="selectUser(user)" class="tooltip-user" [ngClass]="{'tooltip-user-selected': getUserChosen() == user.UserId}">
            <h6 class="light">{{user.UserName}}</h6>
            @if (toolTipMode == "user-planning") {
                <input type="checkbox" [(ngModel)]="user.UserChecked"/>               
            }
        </div>
    </div>
    @if(toolTipMode == "user-planning") {
        <div class="tooltip-user-button">
            <button (click)="confirmUserSelected()"><h5 class="light">Valider</h5></button>
        </div>
    }
</div>