import { Event } from "./event"
import { constructorUser, User } from "./user"

export interface DayUserEvent {
    DayUserEventDate: string;
    DayUserEventConflict: boolean;
    DayUserEventUser: User;
    DayUserEvents: Event[];
}


export function constructorDayUserEvent(): DayUserEvent {
    return {
        DayUserEventDate: '',
        DayUserEventConflict: false,
        DayUserEventUser: constructorUser(),
        DayUserEvents: []
    }
}