import { Component } from '@angular/core';
import { IconComponent } from '../widgets/icon/icon.component';
import { CommonModule, DatePipe, Location } from '@angular/common';
import { EventCardComponent } from '../event-card/event-card.component';
import { EventService } from '../services/event.service';
import { constructorDayUserEvent, DayUserEvent } from '../models/day-user-event';
import { EventListFAObject } from '../models/user-event-list-object';
import { Router } from '@angular/router';
import { Event } from '../models/event';
import { Planning } from '../models/planning';

@Component({
  selector: 'app-planning-fa',
  standalone: true,
  imports: [CommonModule, IconComponent, EventCardComponent],
  providers: [DatePipe],
  templateUrl: './planning-fa.component.html',
  styleUrl: './planning-fa.component.scss'
})
export class PlanningFaComponent {

  event!: Event;
  usersPlanning!: Planning;
  tabDayUserEvent: DayUserEvent[] = [];
  eventListFA!: EventListFAObject;
  tabGaugeStep: number[] = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
  listUserSelected: string = "";
  isLoaded: boolean = false;

  constructor(
    private location: Location,
    private router: Router,
    private eventSvc: EventService,
    private datePipe: DatePipe
  ) {
    const navigation = this.router.getCurrentNavigation();
    
    const state = navigation?.extras.state as { _event: Event };
    if (state) {
      console.log(state._event);
      this.event = state._event;
      this.getEventListFA(this.event.EventFA.FicheActionId);
    }
  }

  getEventListFA(_ficheActionId: number) {
    this.tabDayUserEvent = [];
    this.eventSvc.getEventListFA(_ficheActionId).subscribe(
      {
        next: (_eventListFA: EventListFAObject) => {
          console.log("Mon planning FA" , _eventListFA);
          this.eventListFA = _eventListFA;
          /* if (_userEventList) {
            //this.listUserSelected = _userEventList?.UserEventListUserList;
            this.usersPlanning = _userEventList.UserEventListPlanning; 
            this.generatePlanningObject();
            this.isLoaded = true;
          } */
          this.setGauge(this.eventListFA.EventListFADayBudget, this.eventListFA.EventListFADayTotal);
          this.isLoaded = true;
        },
        error: _error => {
          console.log("Erreur planning FA", _error);
          if (_error.error) {
            alert("Error lors du chargement des informations de la fiche action" + _error.error.Error.ErrorMessage);
          }
        }
      }
    )
  }

  generatePlanningObject() {
    this.listUserSelected.split(';').map(_userId => {
      let planningbyUserEvents: DayUserEvent = constructorDayUserEvent();

      this.usersPlanning.PlanningDayUserEvents.map(_planning => {
        if (String(_planning.DayUserEventUser.UserId) == _userId) {
          //Set new User Object
          planningbyUserEvents.DayUserEventUser.UserId = _planning.DayUserEventUser.UserId;
          planningbyUserEvents.DayUserEventUser.UserName = _planning.DayUserEventUser.UserName;
          planningbyUserEvents.DayUserEventUser.UserShortName = _planning.DayUserEventUser.UserShortName;
          planningbyUserEvents.DayUserEventUser.UserEntityLabel= _planning.DayUserEventUser.UserEntityLabel;
          planningbyUserEvents.DayUserEventDate = _planning.DayUserEventDate;
          planningbyUserEvents.DayUserEvents = planningbyUserEvents.DayUserEvents.concat(_planning.DayUserEvents);
        }
      });
      this.tabDayUserEvent.push(planningbyUserEvents);
    });
    
    console.log("MARMITE",this.tabDayUserEvent);
  }

  /* generatePlanningFAObject() {
    this.usersPlanning.PlanningDayUserEvents.map(_planning => {
      if (String(_planning.DayUserEventUser.UserId) == _userId) {
        //Set new User Object
        planningbyUserEvents.DayUserEventUser.UserId = _planning.DayUserEventUser.UserId;
        planningbyUserEvents.DayUserEventUser.UserName = _planning.DayUserEventUser.UserName;
        planningbyUserEvents.DayUserEventUser.UserShortName = _planning.DayUserEventUser.UserShortName;
        planningbyUserEvents.DayUserEventUser.UserEntityLabel= _planning.DayUserEventUser.UserEntityLabel;
        planningbyUserEvents.DayUserEvents = planningbyUserEvents.DayUserEvents.concat(_planning.DayUserEvents);
      }
    });
    
    console.log("MARMITE",this.tabDayUserEvent);
  } */

  transformDate(_date: string) {
    const year = parseInt(_date.substring(0, 4), 10);
    const month = parseInt(_date.substring(4, 6), 10) - 1; // Les mois commencent à 0 en JavaScript
    const day = parseInt(_date.substring(6, 8), 10);

    let date: Date = new Date(year, month, day);
    
    return this.datePipe.transform(date,"dd/MM/YYYY");
  }

  setGauge(maxBudget: number = 20, usedBudget: number = 16) {
    //console.log(usedBudget < 0);
    
    this.tabGaugeStep = [];
    for (let index = 0; index < maxBudget; index++) {
      this.tabGaugeStep.push(index < usedBudget ? 1 : 0);
    }
  }

  backToCalendar() {
    this.location.back();
  }
}
