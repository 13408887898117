import { HttpContextToken, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { catchError } from 'rxjs';

export const NO_TOKEN = new HttpContextToken<boolean>(() => false);

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {

  const authSvc = inject(AuthenticationService);

  // Do not intercept the request if specifically asked.
  if(req.context.get(NO_TOKEN) === true){
    return next(req);
  }
  
  //Check if there is already a token in localstorage
 /*  let user = this.tokenSvc.getUser()
  if (user?.token) {
    this.tokenSvc.setUser(user)
  } */
  
  // Add token to the headers
  const tokenizedReq = req.clone({
    headers: req.headers.append("token", authSvc.userToken)
  })
  
  return next(tokenizedReq).pipe(
    catchError((err) => {
      if (err.status === 401){
        console.error("Unauthorized", "removing token");
        authSvc.deleteToken();
      }
      throw err;
    })
  )
};
