import { ApplicationConfig, provideZoneChangeDetection, isDevMode, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';

//Date Local
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { tokenInterceptor } from './interceptors/token.interceptor';
import { offlineInterceptor } from './interceptors/offline.interceptor';

registerLocaleData(localeFr);

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withFetch(),
      withInterceptors([offlineInterceptor, tokenInterceptor]),
    ),
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes), 
    provideServiceWorker('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000'
    }), 
    {provide: LOCALE_ID, useValue: 'fr-FR' },
    ]
};