import { Component } from '@angular/core';
import { AppUpdateService } from '../services/app-update.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-install-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './install-toast.component.html',
  styleUrl: './install-toast.component.scss'
})
export class InstallToastComponent {

  public installStep$ : BehaviorSubject<0|1|2> = new BehaviorSubject<0|1|2>(1);
  public updateStep$ : BehaviorSubject<0|1> = new BehaviorSubject<0|1>(1);

  showInstall$ : Observable<boolean>;
  showUpdate$: Observable<boolean>;

  constructor(
    private updateSvc: AppUpdateService
  ){
    this.showInstall$ = updateSvc.canInstallApp$;
    this.showUpdate$ = updateSvc.canUpdateApp$;    
  }

  install(){
    this.updateSvc.install();
  }

  update(){
    this.updateSvc.update();
  }

  installStep2(){
    this.installStep$.next(2);
  }

  cancelInstall(){
    this.installStep$.next(0);
  }

  cancelUpdate(){
    this.updateStep$.next(0);
  }


}
