import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Entity } from '../models/entity';
import { filter, from, Subscription, toArray } from 'rxjs';
import { UserService } from '../services/user.service';
import { constructorUser, User } from '../models/user';
import { EntityObject } from '../models/entity-object';
import { IconComponent } from '../widgets/icon/icon.component';
import { CommonModule } from '@angular/common';
import { ParamService } from '../services/param.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-tooltip-user',
  standalone: true,
  imports: [IconComponent, CommonModule, FormsModule],
  templateUrl: './tooltip-user.component.html',
  styleUrl: './tooltip-user.component.scss'
})

export class TooltipUserComponent {

  entitySelected!: Entity | null;
  entitySelectedFiltered!: Entity;
  isListFiltered: boolean = false;
  filterInputFieldValue: string = "";

  entityList!: Entity[];

  userSelected!: User | null;

  currentUser: User | null = constructorUser();
  userSubscription?: Subscription;
  isAnotherUserSelected: boolean = false;
  toolTipMode: string = "user"; //"user" or "user-planning"

  @Input() set typeTooltip(_typeTooltip: string) {
    this.toolTipMode = _typeTooltip;
  }

  @Input() set selectUserList(_users: User[]) {
    if (_users) {
      this.unselectUser(_users);
    } 
  }

  @Output() tooltipUserClose: EventEmitter<boolean> = new EventEmitter(false);

  @Output() tooltipListUserSelected: EventEmitter<User[]> = new EventEmitter();

  constructor(
    private userSvc: UserService,
    private paramSvc: ParamService
  ) {
    if (this.paramSvc.entityList) {
      this.entityList = this.paramSvc.entityList;
      this.selectEntity(this.entityList[0]);
    }
  }

  filterUser() {
    console.log(this.filterInputFieldValue);
    
    if (!this.entitySelected?.EntityUsers) return;

    from(this.entitySelected?.EntityUsers)
      .pipe(
        filter(user => user.UserName.toLowerCase().includes(this.filterInputFieldValue.toLowerCase())),
        toArray()
      )
      .subscribe(filtered => {
        this.entitySelectedFiltered.EntityUsers = filtered;
        //console.log(filtered);
    });

    this.isListFiltered = this.filterInputFieldValue == "" ? false : true;
  }

  removefilterUser() {
    this.filterInputFieldValue = "";
    this.filterUser();
    this.isListFiltered = false;
  }

  selectEntity(_entity: Entity) {
    this.entitySelected = _entity;
    this.entitySelectedFiltered = {...this.entitySelected};
    this.isListFiltered = false;
  }

  selectUser(_userSelected: User) {
    if (this.toolTipMode == "user") {
      this.userSvc.setUserChosen(_userSelected);
      this.closeTooltip();
      this.removefilterUser();
    } else {
      _userSelected.UserChecked = !_userSelected.UserChecked;
    }
  }

  getUserChosen() {
    return this.userSvc.getUserChosen().UserId;
  }

  closeTooltip() {
    this.tooltipUserClose.emit(true);
  }

  confirmUserSelected() {
    this.removefilterUser();
    let tabUserSelected: User[] = [];

    this.entityList.map(_entity => {
      tabUserSelected = [...tabUserSelected, ..._entity.EntityUsers.filter(_user => _user.UserChecked)];
    });

    console.log("JEMIT ma selection de user : ", tabUserSelected);
    if (tabUserSelected.length > 0) {
      this.tooltipListUserSelected.emit(tabUserSelected);
    }

    this.closeTooltip();
  }

  unselectUser(_userId: User[]) {
    this.entityList.map(_entity => {
       _entity.EntityUsers.filter(_user => _user.UserChecked).map(_userFiltered => {
        _userFiltered.UserChecked = _userId.includes(_userFiltered);
       });
    });
  }
}
