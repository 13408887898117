export interface YearMonth {
    year: number;
    month: number;
}

export function stringToYearMonth(str : string): YearMonth{
    return {
        month: Number(str.substring(4,6)),
        year: Number(str.substring(0,4))
    }
}

export function dateToYearMonth(dt: Date): YearMonth{
    return {
        month: dt.getMonth() + 1,
        year: dt.getFullYear()
    }
}

export function getNextYearMonth(ym: YearMonth): YearMonth{
    const next : YearMonth = {
        month: ym.month,
        year: ym.year
    }

    next.month += 1;
    if(next.month > 12){
        next.year += 1;
        next.month -= 12;
    }
    return next;
}

export function getPreviousYearMonth(ym: YearMonth): YearMonth{
    const prev : YearMonth = {
        month: ym.month,
        year: ym.year
    }

    prev.month -= 1;
    if(prev.month < 1){
        prev.year -= 1;
        prev.month += 12;
    }
    return prev;
}

export function yearMonthToString(ym: YearMonth): string{
    return String(ym.year) +'-'+ String(ym.month).padStart(2,'0');
}

export function compareYearMonths(ym1: YearMonth, ym2: YearMonth) : boolean{
    return ym1.year == ym2.year && ym1.month == ym2.month;
}

export function isDateInYearMonth(dt: Date, ym: YearMonth) : boolean{
    return ym.year == dt.getFullYear() && (dt.getMonth()+1) === ym.month;
}

export function getFirstDayOf(ym: YearMonth): string{
    return String(ym.year) + String(ym.month).padStart(2,'0') + '01';
}
export function getLastDayOf(ym: YearMonth): string{
    return String(ym.year) 
        + String(ym.month).padStart(2,'0') 
        + String((new Date(ym.year, ym.month, 0)).getDate()).padStart(2,'0');
}


export function dateToString(dt: Date) : string{
    return String(dt.getFullYear()) 
        + String(dt.getMonth()+1).padStart(2,'0') 
        + String(dt.getDate()).padStart(2,'0')
    ;
}