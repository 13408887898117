<div class="home-calendar-container">
    <div class="home-calendar-header">
        <app-calendar-header (clickRefreshCalendar)="refreshCalendar()"></app-calendar-header>
    </div>
    <div class="home-calendar">
        <app-calendar 
            [allEvents$]="allEvents$ | async"
            [displayedMonthYear$]="periodDisplayed$ | async"
            [selectDay]="dateSelected$ | async"
            (dayClicked)="dateClicked($event)">
        </app-calendar>
    </div>
    <div class="home-calendar-buttons">
        <div class="button-calendar">
            <div class="left-button" (click)="changeDate('-')"></div>
            <div class="date-label-container">
                <div class="date-label-content">
                    <h6 class="regular" *ngIf="periodLabel$ | async as label">{{label}}</h6>
                </div>
            </div>
            <div class="right-button" (click)="changeDate('+')"></div>
        </div>
    </div>
    <div class="home-calendar-event-container">
        <app-calendar-event-list 
            [eventDaySelected]="fullDay$ | async"
        ></app-calendar-event-list>
    </div>
</div>