import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss'
})
export class IconComponent implements OnChanges{

  @Input() public iconLabel?: string;
  @Input() public isPointer: boolean = true;

  public svgIcon: any;

  constructor() {}

  public ngOnChanges(): void {
    this.svgIcon = '/assets/icons/' + this.iconLabel + '.svg';
  }
}
