<div class="calendar-event-list-container">
    <div class="list-button">
        @if (isUserAuthorized && !!(isOnline$ | async)) {
            <app-icon class="add-button" [iconLabel]="'plus'" (click)="tooltipOpen = !tooltipOpen"></app-icon>
        }
        <app-tooltip-event [openCloseTooltip]="tooltipOpen" [daySelected]="daySelected"></app-tooltip-event>
    </div>
    <div class="list-event">
        <div class="list-event-content">
            <app-event-card *ngFor="let event of listEvent!" 
                [eventObj]="event" 
                [deletableEvent]="isUserAuthorized && !!(isOnline$ | async)" 
                [clickableEvent]="true" 
                (deleteEvent)="deleteEvent($event)"
            ></app-event-card>
        </div>
        @if (daySelected && daySelected.DayIsConflict) {
            <div class="list-event-conflict">
                <div class="line-conflict-top"></div>
                <app-icon class="icon-conflict" [iconLabel]="'conflict-red'"></app-icon>
                <div class="line-conflict-bottom"></div>
            </div>
        }
    </div>
    @if (isTodayFriday && isUserConnected && (isOnline$ | async)) {
        <div class="note-rating">
            <div class="listStar" *ngFor="let bFilled of tabNoteUser; let i = index">
                <svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="changeUserNote(i)">
                    <path [ngClass]="{'star-selected' : bFilled }" class="star" d="M18 0L22.0413 12.4377H35.119L24.5389 20.1246L28.5801 32.5623L18 24.8754L7.41987 32.5623L11.4611 20.1246L0.880983 12.4377H13.9587L18 0Z" fill="#CAE3CD"/>
                </svg>
            </div>
        </div>
    }
</div>
