import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { BYPASS_OFFLINE_CHECK, NetworkStatusService } from '../services/network-status.service';
import { catchError, throwError } from 'rxjs';

export const offlineInterceptor: HttpInterceptorFn = (req, next) => {
  
  const offlineSvc = inject(NetworkStatusService);
  
  return next(req).pipe(
    catchError((err) => {
      console.error("Offline error", err.status, err);

      if(req.context.get(BYPASS_OFFLINE_CHECK)){
        // Avoid triggering triggerOffline on the request that is checking if we're back online 
        // (/api?description, see NetworkStatusService
        return throwError(() => err);
      }

      // Catch basic network error
      if(err instanceof HttpErrorResponse && !err.headers.keys().length && !err.ok && !err.status && !err.error.loaded && !err.error.total){
        offlineSvc.triggerOffline();
      }
      // Catch service unavailable error
      else if ([502, 503, 504, 0].lastIndexOf(err.status) > -1){
        offlineSvc.triggerOffline();
      }
      return throwError(() => err);
    })
  )
};
