import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from "../widgets/icon/icon.component";
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EventCost, EventCostType } from '../models/event-cost';
import { ParamService } from '../services/param.service';
import { Combo } from '../models/combo-object';

@Component({
  selector: 'app-cost-card',
  standalone: true,
  imports: [IconComponent, CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './cost-card.component.html',
  styleUrl: './cost-card.component.scss'
})
export class CostCardComponent {

  deployed: boolean = false;
  costForm!: FormGroup;
  cost!: EventCost;
  typeCombo!: EventCostType[];
  isDeletable: boolean = false;

  //Listen day selection from parent
  @Input() set EventCost(_eventCost: EventCost) {
    if (_eventCost) {
      this.cost = _eventCost;      
      this.initEventForm();
    }
  };

  @Input() set deletableEvent(_isDeletable: boolean) {
    this.isDeletable = _isDeletable;    
  };

  @Output() deleteEventCost: EventEmitter<EventCost> = new EventEmitter<EventCost>();

  constructor(
    private paramSvc: ParamService
  ) {
    //Get Combo Type Cost
    let _tempTypeCostList = localStorage.getItem("PPL-TypeCostListObject");
    if (_tempTypeCostList) {
      //Don't display CostTypeAuto 
      this.typeCombo = JSON.parse(_tempTypeCostList);
    }
  }

  initEventForm(): void {
    this.costForm = new FormGroup({
      costType: new FormControl({value: this.cost.EventCostType.CostTypeId, disabled: true}, [Validators.required]),
      costDetail: new FormControl({value: this.cost.EventCostDetail, disabled: true}, [Validators.required]),
      costTTC: new FormControl({value: this.cost.EventCostTTC, disabled: true}, [Validators.required, Validators.pattern(/^[0-9]+$/)]),
      costTVA: new FormControl({value: this.cost.EventCostTVA, disabled: true}, [Validators.required, Validators.pattern(/^[0-9]+$/)]),
      costRebillable: new FormControl({value: this.cost.EventCostRebillable, disabled: true}, [Validators.required]),
      costExpense: new FormControl({value: this.cost.EventCostExpense, disabled: true}, [Validators.required]),
      costOvernight: new FormControl({value: this.cost.EventCostOvernight, disabled: true}, [Validators.required, Validators.pattern(/^[0-9]+$/)]),
      costKmCO2: new FormControl({value: this.cost.EventCostKmCO2, disabled: true}, [Validators.required, Validators.pattern(/^[0-9]+$/)]),
    });
  }

  changeHeight() {
    //this.deployed = !this.deployed;
  }

  deleteCost() {
    this.deleteEventCost.emit(this.cost);
  }
}
