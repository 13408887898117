import { Component } from '@angular/core';
import { CalendarHeaderComponent } from "../calendar-header/calendar-header.component";
import { CommonModule, DatePipe } from '@angular/common';
import { CalendarComponent } from "../calendar/calendar.component";
import { CalendarEventListComponent } from '../calendar-event-list/calendar-event-list.component';
import { Day } from '../models/day';
import { EventService } from '../services/event.service';
import { User } from '../models/user';
import { UserService } from '../services/user.service';
import { catchError, combineLatest, distinctUntilChanged, filter, map, merge, Observable, of, race, shareReplay, Subject, switchMap, take, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ParamService } from '../services/param.service';
import { CalendarService } from '../services/calendar.service';
import { constructorDayUserEvent, DayUserEvent } from '../models/day-user-event';
import { dateToString, dateToYearMonth, getFirstDayOf, getLastDayOf, getNextYearMonth, getPreviousYearMonth, stringToYearMonth, YearMonth, yearMonthToString } from '../models/year-month';
import { NetworkStatusService } from '../services/network-status.service';

// Tableau des noms des mois en français
const monthOfYear: string[] = [
    '', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
];


@Component({
  selector: 'app-home-calendar',
  standalone: true,
  imports: [CalendarHeaderComponent, CommonModule, CalendarComponent, CalendarEventListComponent],
  providers: [DatePipe],
  templateUrl: './home-calendar.component.html',
  styleUrl: './home-calendar.component.scss'
})

export class HomeCalendarComponent {

  public allEvents$ : Observable<DayUserEvent[]>;
  public periodDisplayed$: Observable<YearMonth>;
  public periodLabel$: Observable<string>;
  public dateSelected$: Observable<string>;
  public fullDay$: Observable<Day>;

  private __periodSelected$: Subject<YearMonth> = new Subject<YearMonth>();
  private __currentPeriod: YearMonth = dateToYearMonth(new Date());
  
  monthLoaded: YearMonth[] = [];
  monthToLoad: YearMonth[] = [];
  tooltipOpen: boolean = false;

  constructor(
    private userSvc: UserService,
    private paramSvc: ParamService,
    private router: Router,
    private calendarSvc: CalendarService,
    route: ActivatedRoute
  ) {

    this.paramSvc.getEntity();
    this.paramSvc.getParam();
    this.paramSvc.getCostType();

    const userId$: Observable<string> = this.userSvc.user$.pipe(
      map(user => { if(user === null) return {UserId: 0} as User; return user;}),
      filter(user => user.UserId > 0),
      map(user => user.UserId.toString()),
      distinctUntilChanged(),
      shareReplay({bufferSize: 1, refCount: true}) //Alllow to be sure to execut only once
    )

    const yearMonth$ : Observable<YearMonth> = merge(
      this.__periodSelected$,
      race(
        calendarSvc.dateFromRoute$.pipe(map(s => stringToYearMonth(s))),
        of(dateToYearMonth(new Date())).pipe()
      )
    ).pipe(
      distinctUntilChanged((prev, curr) => curr.year === prev.year && curr.month === prev.month),
      tap((newPeriod) => { this.__currentPeriod = newPeriod}),
      shareReplay({bufferSize: 1, refCount: true}) //Alllow to be sure to execut only once
    )

    this.allEvents$ = combineLatest([
      yearMonth$,
      userId$,
      merge(
        route.queryParamMap.pipe(
          filter(m => m.has('refresh')),
          map(v => Date.now())
        ),
        of(route.snapshot.queryParamMap.has('refresh') ? Date.now() : 0), 
        this.calendarSvc.refreshCalendar$.pipe(map(v => Date.now()))
      )
    ]).pipe(
      switchMap(([yearMonth, userId, lastRefreshAsked]) => {

        // refreshInterval allows us to detect if the switchMap was triggered by refreshCalendar$, 
        // in this case we put forceRefresh to true
        const refreshInterval = Date.now() - lastRefreshAsked;
        
        return merge(
          of([]), // Allow the calendar to "empty" before being filled by API
          this.calendarSvc.getUserPlanning(yearMonth, userId, refreshInterval > -1 && refreshInterval < 100),
          // Those below are "special", we subscribe to it to trigger the call to the API, but the filter operator prevent them to be really used
          // This enables us to always cache next and previous months so that going to them is super fast
          this.calendarSvc.getUserPlanning(getPreviousYearMonth(yearMonth), userId, false).pipe(take(1), filter(e => false)),
          this.calendarSvc.getUserPlanning(getNextYearMonth(yearMonth), userId, false).pipe(take(1), filter(e => false)),
        )
      }),
      shareReplay({bufferSize: 1, refCount: true}) // Alllow to be sure to execute only once
    )
   
    this.periodDisplayed$ = yearMonth$.pipe(
      shareReplay({bufferSize: 1, refCount: true}) // Alllow to be sure to execute only once
    );

    this.periodLabel$ = this.periodDisplayed$.pipe(
      map((ym) => {
        return monthOfYear[ym.month] + ' ' + ym.year.toString();
      }),
      shareReplay({bufferSize: 1, refCount: true})
    )

    this.dateSelected$ = merge(
      of(new Date()).pipe(map((dt) => dateToString(dt))),
      calendarSvc.dateFromRoute$,
    ).pipe(
      tap((s) => { this.__periodSelected$.next(stringToYearMonth(s))}),
      shareReplay({bufferSize: 1, refCount: true})
    )


    this.fullDay$ = combineLatest([
      this.dateSelected$,
      this.allEvents$
    ]).pipe(
      map(([date, events]) => events.filter(d => d.DayUserEventDate === date)),
      map(days => {
        if(days.length == 0){
          return [constructorDayUserEvent()];
        }
        return days;
      }),
      map(days => days[0]),
      map((dayFromPlanning) : Day => {
        return {
          DayDate : new Date(),
          DayDateLabel: dayFromPlanning.DayUserEventDate,
          DayEvents: dayFromPlanning.DayUserEvents,
          DayIsConflict: dayFromPlanning.DayUserEventConflict,
          DayNumber: 1,
          DayOfTheWeek: 0 
        }
      }),
      shareReplay({bufferSize: 1, refCount: true})
    )
  }



  //Change Calendar month
  changeDate(sens : '+' | '-') {
    if(sens === '+'){
      this.__periodSelected$.next(getNextYearMonth(this.__currentPeriod));
    }
    else{
      this.__periodSelected$.next(getPreviousYearMonth(this.__currentPeriod));
    }
  }

  //Detect Children Calendar date change, and navigate
  dateClicked(_day: string) {
    this.router.navigate(['calendar/', this.userSvc.getUserChosen().UserId, _day]); 
  }

  refreshCalendar() {
    this.calendarSvc.refreshCalendar();
  }
}
