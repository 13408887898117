<div class="calendar-header-container">
    <div class="blue">
        <div class="blue-btn-home" [ngClass]="(isAnotherUserSelected) ? 'orange-btn-close' : 'blue-btn-user'" (click)="clickOnButtonHomeOrClose()">
            @if (isAnotherUserSelected) {
                <app-icon class="blue-btn-home-icon" [iconLabel]="'close'"></app-icon>
            } @else {
                <app-icon class="blue-btn-home-icon" [iconLabel]="'user'"></app-icon>
            }
        </div>
        @if (tooltipOpen) {
            <app-tooltip-user [typeTooltip]="'user'" (tooltipUserClose)="closeTooltipUser($event)"></app-tooltip-user>
        }
    </div>
    <div class="green">
        <div class="green-username">
            <h5 class="regular green-username-label">{{currentUser?.UserShortName}}</h5>
        </div>
    </div>
    <div class="grey">
        <app-icon class="btn-planning" [iconLabel]="'planning'" (click)="openPlanning()"  *ngIf="isOnline$ | async"></app-icon>
    </div>
    <div class="white">
        @if(isOnline$ | async){   
            <app-icon class="btn-refresh" [iconLabel]="'refresh'" (click)="refreshCalendar()"></app-icon>
        }
        @else {
            <svg width="28" height="28" fill="#e89804" viewBox="0 0 36 36" version="1.1" 
                preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g>
                    <circle cx="18" cy="29.54" r="3"></circle>
                    <path d="M29.18,17.71l.11-.17a1.51,1.51,0,0,0-.47-2.1A20.57,20.57,0,0,0,18,12.37c-.56,0-1.11,0-1.65.07l3.21,3.21a17.41,17.41,0,0,1,7.6,2.52A1.49,1.49,0,0,0,29.18,17.71Z"></path>
                    <path d="M32.76,9.38A27.9,27.9,0,0,0,10.18,6.27L12.81,8.9A24.68,24.68,0,0,1,31.1,12.12a1.49,1.49,0,0,0,2-.46l.11-.17A1.51,1.51,0,0,0,32.76,9.38Z"></path>
                    <path d="M3,4.75l3.1,3.1A27.28,27.28,0,0,0,3.18,9.42a1.51,1.51,0,0,0-.48,2.11l.11.17a1.49,1.49,0,0,0,2,.46,24.69,24.69,0,0,1,3.67-1.9l3.14,3.14a20.63,20.63,0,0,0-4.53,2.09,1.51,1.51,0,0,0-.46,2.1l.11.17a1.49,1.49,0,0,0,2,.46A17.46,17.46,0,0,1,14.25,16l3.6,3.6a13.39,13.39,0,0,0-6.79,1.93,1.5,1.5,0,0,0-.46,2.09l.1.16a1.52,1.52,0,0,0,2.06.44,10.2,10.2,0,0,1,9-.7L29,30.75l1.41-1.41-26-26Z"></path> 
                </g>
            </svg>
        }
    </div>
</div>