import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-authentication',
  standalone: true,
  imports: [],
  templateUrl: './authentication.component.html',
  styleUrl: './authentication.component.scss'
})
export class AuthenticationComponent {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router : Router,
    private authSvc: AuthenticationService,
    private userSvc: UserService
  ) {
        
    this.activatedRoute.queryParamMap.subscribe(params => {
      let _paramToken = params.get('token');

      if (_paramToken) {
        this.authSvc.setToken(_paramToken);
        this.authSvc.userToken = _paramToken;
        this.userSvc.getUserAuthenticated();
      }
    });
  }
}
